.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background: url('../../assets/images/YellowLines.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 1000px;
    background-color: white;
    box-shadow: 0 0 40px rgba(0,0,0,0.16);
    overflow: hidden;
    margin: 0 auto;
    border-radius: 12px;
  }
  #login-form {
    flex: 1 0 100%;
    max-width: 480px;
    width: 100%;
    padding: 60px;
  }
  #login-form p {
    margin-bottom: 30px;
  }



.ant-input-affix-wrapper{
    padding: 4px 11px;
}


.ant-btn-primary{
    background: #e9aa06 !important;
    box-shadow:#e9aa06;
    border: none;
}
.ant-form-item-control-input-content{
    flex: none;
    /* padding-top: 30px; */
}

.ant-btn-primary:hover{
    background: #e9aa06  !important;
    box-shadow:#e9aa06;
    border: none;
}
.ant-btn-primary:active{
  background: #e9aa06  !important;
  box-shadow:#e9aa06;
  border: none;
}
.ant-form-item-label{
    width: 86px;
}

.ant-input-affix-wrapper{
    width: 183px;
}

.logo{
  padding-left: 32px;
  padding-top: 16px;
}